<script setup lang="ts">
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
    placeholder: {
        type: String,
        default: 'Rechercher'
    },
    type: {
        type: String,
        default: 'text'
    },
    name: {
        type: String,
        default: ''
    },
    theme: {
        type: String,
        default: 'light'
    },
    required: {
        type: Boolean,
        default: false
    },
});

const model = defineModel({
    default: '',
});

const focus = ref(false);
const container = ref(null);

onClickOutside(container, () => {
    focus.value = false;
});

const inputError = ref<string | null>(null);

const handleValidate = () => {
    if(props.required && model.value === '') {
        inputError.value = 'Ce champ est obligatoire';
    } else {
        inputError.value = null;
    }

    if (props.type === 'date') {
        const date = new Date(model.value);
        if (isNaN(date.getTime())) {
            model.value = '';
            inputError.value = 'La date n\'est pas valide';
        }
    }

    if(props.type === 'email') {
        const regex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
        if(!regex.test(model.value)) {
            model.value = '';
            inputError.value = 'L\'adresse email n\'est pas valide';
        }
    }

    if(props.type === 'tel') {
        const regex = new RegExp('^(?:(?:\\+33|0033|0)(?:\\s|-|\\.|)?(?:[1-9])(?:\\s|-|\\.|)?(?:\\d{2}(?:\\s|-|\\.|)?){4})$');
        if(!regex.test(model.value)) {
            model.value = '';
            inputError.value = 'Le numéro de téléphone n\'est pas valide';
        }
    }

    if(props.name === 'zip_code') {
        const regex = new RegExp('^(?:[0-8]\\d|9[0-8])\\d{3}$');
        if(!regex.test(model.value)) {
            model.value = '';
            inputError.value = 'Le code postal n\'est pas valide';
        }
    }
}
</script>

<template>
    <div ref="container" class="flex flex-col relative w-full">
        <label :for="props.name"
            :class="`uppercase ${props.theme === 'light' ? 'text-dark' : 'text-white'} opacity-50 absolute ${focus || model || type === 'date' ? 'text-xs -top-3' : 'text-base top-2.5'} transition-all duration-150 ease-in-out`">
            {{ props.placeholder }}
        </label>
        <input :id="props.name" :name="props.name" :type="props.type" @change="handleValidate"
            :class="`border-b ${props.theme === 'light' ? 'border-secondary text-dark' : 'border-white text-white'} py-2 outline-none bg-transparent w-full`"
            @focus="focus = true" v-model="model" autocomplete="on" :required="props.required">
        <span v-if="inputError" class="text-red pt-1 text-xs">
            {{ inputError }}
        </span>
    </div>
</template>
