import { handleFormSubmit, handleClickOutsideForm, getProgramContactSession } from '@/utils/forms';
import { useGtm } from '@/composables/useGtm';

document.addEventListener("DOMContentLoaded", function () {
    const formContainer: HTMLFormElement | null = document.querySelector(".brochure-form");
    let formPosition: string | 'Unspecified';
    const submitButton: HTMLElement | null = document.querySelector(".submitBrochureForm");
    const downloadButton = formContainer?.querySelector('.downloadBookletProgram');

    document.querySelectorAll('.openBrochureForm')?.forEach(item => {
        item?.addEventListener('click', (e) => {
            e.stopPropagation();

            if(formContainer) formContainer.classList.toggle('hidden');

            if(formContainer && !formContainer.classList.contains('hidden')) {
                const form = formContainer.querySelector('form');
                if(!form) return
                
                handleClickOutsideForm(formContainer);

                // Handle download booklet program if form already submitted
                const idOperation = form?.getAttribute('data-program-id-operation');
                formPosition = item?.getAttribute('data-position') || 'Unspecified';

                if(!!idOperation && getProgramContactSession(idOperation, 'brochure')) {
                    formContainer.querySelector('.downloadBookletProgram')?.classList.remove('hidden');
                    form.classList.add('!hidden');
                    downloadButton?.addEventListener('click', () => {
                        useGtm('download_plaquette');
                    })
                }
            }
        })
    });

    document.querySelector('#closeBrochureForm')?.addEventListener('click', () => {
        formContainer?.classList.add('hidden');
    })

    formContainer?.addEventListener('submit', (event) => {
        event.preventDefault();

        const formData = new FormData(event.target as HTMLFormElement);
        handleFormSubmit(formData, submitButton, 'brochure-form', 'brochure');
        useGtm('submit_download_plaquette', formPosition);
    })
});
