import * as gtm from './gtm';
import axios from 'axios';
// const inViewport ("in-viewport");

$(document).ready(function() {
    // Check if the contact form is in viewport
    // inViewport(document.getElementById("contactform"), function () {
    //     gtm.gae("Formulaire", "Est affiché");
    // });

    // Show the ground form in modal
    $(".ground_button").click(function () {
        $(".modal_overlay").removeClass("hidden");
        $(".modal").removeClass("hidden");
        $("body").addClass("overflow-hidden");
    });

    // Show the seller form in modal
    $(".vendeur_appel_button").click(function () {
        $(".modal_overlay").removeClass("hidden");
        $("#vendeur_rdv_modal").removeClass("hidden");
        $("body").addClass("overflow-hidden");
        $("#contactform_bis").parent().children("h2").text("Être rappelé");
        $('[name="form"]').val("Être rappelé");
    });

    // Show the seller form in modal
    $(".vendeur_rdv_button").click(function () {
        $(".modal_overlay").removeClass("hidden");
        $("#vendeur_rdv_modal").removeClass("hidden");
        $("body").addClass("overflow-hidden");
        $("#contactform_bis").parent().children("h2").text("Être rappelé");
        $('[name="form"]').val("Être rappelé");
    });

    // Show the price form in modal
    $(".vendeur_price_button").click(function () {
        $(".modal_overlay").removeClass("hidden");
        $("#vendeur_rdv_modal").removeClass("hidden");
        $("body").addClass("overflow-hidden");
        $("#contactform_bis").parent().children("h2").text("Être recontacté");
        $('[name="form"]').val("Être recontacté");
    });

    // Go to the contact form
    $('a[href="#contactform"]').click(function (event) {
        event.preventDefault();
        if ($("#contactform").length) {
            if ($(window).width() > 992) {
                $("html, body").animate(
                    {
                        scrollTop: $($.attr(this, "href")).offset().top - 56,
                    },
                    500,
                );
            } else {
                $("html, body").animate(
                    {
                        scrollTop: $($.attr(this, "href")).offset().top - 118,
                    },
                    500,
                );
            }
        } else {
            document.location.href = "/#contactform";
        }
    });

    // Show error message on forms
    $('[name="consens"]').change(function () {
        if ($(this).is(":checked")) {
            $(".consens_confirm_error").addClass("hidden");
        }
    });

    window.parsleyjs?.on("field:error", function () {
        if ($(".modal").find(".parsley-error").length > 0) {
            $(".modal").animate(
                {
                    scrollTop: 0,
                },
                500,
            );
        }
    });

    // Send application form
    $("#candidature").submit(function (event) {
        event.preventDefault();
        var form = $(this);
        form.find(':disabled').prop('disabled', false);

        var form_data = new FormData(form[0]);
        form.find(".contactform_field-message").removeClass("hidden");
        axios
            .post(urls.ajax, form_data)
            .then(function () {
                form.find(".contactform_field-message").addClass("hidden");
                form[0].reset();
                form.find('[name="type"]').attr("disabled", false);
                form.find('[name="job"]').attr("disabled", false);
                form.find(".contactform_message").removeClass("hidden");
                setTimeout(function () {
                    form.find(".contactform_message").addClass("hidden");
                }, 3000);
                gtm.gtmEvent("Lead", {
                    form: {
                        id: form
                            .find('[name="form"]')
                            .val()
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replace(/[^\w-]+/g, ""),
                        title: form.find('[name="form"]').val(),
                    },
                    success: true,
                });
            })
            .catch(function (error) {
                console.error(error);
                form[0].reset();
                gtm.gtmEvent("Lead", {
                    form: {
                        id: form
                            .find('[name="form"]')
                            .val()
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replace(/[^\w-]+/g, ""),
                        title: form.find('[name="form"]').val(),
                    },
                    success: false,
                });
            });
    });

    // Send sponsorship and ground form
    // $("#contactform_parrainage").submit(function (event) {
    //     event.preventDefault();
    //     var form = $(this);
    //     var form_data = new FormData(form[0]);
    //     axios
    //         .post(urls.ajax, form_data)
    //         .then(function () {
    //             form[0].reset();
    //             form.find(".contactform_message").removeClass("hidden");
    //             setTimeout(function () {
    //                 form.find(".contactform_message").addClass("hidden");
    //             }, 3000);
    //             gtm.gtmEvent("Lead", {
    //                 form: {
    //                     id: form
    //                         .find('[name="form"]')
    //                         .val()
    //                         .toLowerCase()
    //                         .replace(/ /g, "-")
    //                         .replace(/[^\w-]+/g, ""),
    //                     title: form.find('[name="form"]').val(),
    //                 },
    //                 success: true,
    //             });
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //             form[0].reset();
    //             gtm.gtmEvent("Lead", {
    //                 form: {
    //                     id: form
    //                         .find('[name="form"]')
    //                         .val()
    //                         .toLowerCase()
    //                         .replace(/ /g, "-")
    //                         .replace(/[^\w-]+/g, ""),
    //                     title: form.find('[name="form"]').val(),
    //                 },
    //                 success: false,
    //             });
    //         });
    // });

    // Send contact and seller form
    $(
        "#contactform, #contactform_bis, #contactform_contact, #contactform_call",
    ).submit(function (event) {
        event.preventDefault();
        var form = $(this);
        var form_data = new FormData(form[0]);
        
        axios
            .post(urls.ajax, form_data)
            .then(function () {
                form[0].reset();
                if ($("#contact_form_name").val() === "Demande de brochure") {
                    form.addClass("hidden");
                    $(".description_brochure").addClass("hidden");
                    $(".contactform_brochure_message").removeClass("hidden");
                } else if (
                    $("#contact_form_name").val() === "Demande de plan"
                ) {
                    form.addClass("hidden");
                    $(".description_plan").addClass("hidden");
                    $(".contactform_plan_message").removeClass("hidden");
                } else {
                    form.find(".contactform_message").removeClass("hidden");
                    setTimeout(function () {
                        form.find(".contactform_message").addClass("hidden");
                        $(".modal").addClass("hidden");
                        $(".modal_overlay").addClass("hidden");
                        $("body").removeClass("overflow-hidden");
                    }, 3000);
                }
                gtm.gtmEvent("Lead", {
                    form: {
                        id: form
                            .find('[name="form"]')
                            .val()
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replace(/[^\w-]+/g, ""),
                        title: form.find('[name="form"]').val(),
                    },
                    program: {
                        id: form.find('[name="id_operation"]').val(),
                        title: form.find('[name="programme"]').val(),
                    },
                    success: true,
                });
            })
            .catch(function (error) {
                console.log(error);
                form[0].reset();
                form.find(".contactform_message").removeClass("hidden");
                setTimeout(function () {
                    form.find(".contactform_message").addClass("hidden");
                }, 3000);
                gtm.gtmEvent("Lead", {
                    form: {
                        id: form
                            .find('[name="form"]')
                            .val()
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replace(/[^\w-]+/g, ""),
                        title: form.find('[name="form"]').val(),
                    },
                    program: {
                        id: form.find('[name="id_operation"]').val(),
                        title: form.find('[name="programme"]').val(),
                    },
                    success: false,
                });
            });
    });
});
