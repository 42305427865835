<script setup>
import {ref, computed, onMounted, watch, nextTick, watchEffect, toRaw} from 'vue';
import axios from 'axios'
import { gae } from '../../scripts/includes/gtm';

const props = defineProps({
    programs: {
        type: Array,
        default: [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
    displayedComponent: {
        type: String,
        default: 'all',
    },
});

const loadingMap = ref(true)
const mapBoxRef = ref('map_box_search')
const markers = ref([]);
const cluster = ref(null);
const map = ref(null);
const mapObject = ref(null);
const mapInfoWindow = ref(null);
const retries = ref(0)
const isMapInitialized = ref(false)

const emits = defineEmits([
    "update:programs",
    "update:loading"
])

const isValidCoordinate = (lat, lng) => {
    return !isNaN(lat) && !isNaN(lng) && 
           lat !== null && lng !== null && 
           lat !== 0 && lng !== 0;
};

const computedPrograms = computed(() => 
    props.programs.filter(program => 
        isValidCoordinate(parseFloat(program.latitude), parseFloat(program.longitude))
    )
)

const computedLoading = computed({
    get() {
        return props.loading
    },
    set(value) {
        emits("update:loading", value)
    }
})

async function getMarkersProgramDetails(marker, program) {
    if(!program) {
        console.error('No program found !');
        return;
    }
    
    try {
        let data = new FormData();
        data.append("action", "search_infowindow");
        data.append("id", program.post_id);

        const response = await axios.post(urls.ajax, data);
        if(response.status !== 200) throw new Error('Error getting program data for map')

        return response.data

    } catch (error) {
        console.error('Error while getting program details:', error);   
        return null;
    }
}

async function createMarker(map, program) {
    try {
        const lat = parseFloat(program.latitude);
        const lng = parseFloat(program.longitude);
        
        if (!isValidCoordinate(lat, lng)) {
            console.warn('Invalid coordinates for program:', program.id);
            return null;
        }

        const markerPosition = new f4.map.LatLng(lat, lng);
        const marker = new f4.map.Marker({
            position: markerPosition,
            map: map,
            zIndex: 80,
            icon: {
                url: urls.theme + "/assets/images/placeholder.png",
                size: new f4.map.Size(24, 24),
                scaledSize: new f4.map.Size(24, 24),
                anchor: new f4.map.Point(12, 12)
            }
        });

        if(!marker) return null;

        marker.addListener('mouseover', async () => {
            gae(
                "Carte des programmes",
                "Survole",
                program.id
            );

            const details = await getMarkersProgramDetails(marker, program)

            if(!details) {
                console.error('No details found')
                return
            }

            mapInfoWindow.value.setContent(details);
            mapInfoWindow.value.open(map, marker);
        });

        marker.addListener('mouseout', () => {
            mapInfoWindow.value.close()
        });

        marker.addListener('click', () => {
            gae(
                "Carte des programmes",
                "Clique",
                program.id
            );
            window.location.href = program.permalink;
        });

        return marker;

    } catch (error) {
        console.error('Error creating marker:', error);
        return null;
    }
}

async function setMarkers(map) {
    if (!map) {
        setTimeout(() => setMarkers(map), 1000);
        return;
    }

    // Clear existing markers and clusterer
    if (cluster.value) {
        cluster.value.clearMarkers();
    }
    
    const newMarkers = [];
    
    // Track bounds
    let minLat = 90, maxLat = -90, minLng = 180, maxLng = -180;
    
    // Create new markers
    for (const program of computedPrograms.value || []) {
        const marker = await createMarker(map, program);
        if (marker) {
            newMarkers.push(marker);
            
            // Update bounds
            const lat = parseFloat(program.latitude);
            const lng = parseFloat(program.longitude);
            if (isValidCoordinate(lat, lng)) {
                minLat = Math.min(minLat, lat);
                maxLat = Math.max(maxLat, lat);
                minLng = Math.min(minLng, lng);
                maxLng = Math.max(maxLng, lng);
            }
        }
    }

    // Create new clusterer using MarkerClusterer
    if (newMarkers.length && map) {
        // Calculate center and adjust zoom to fit all markers
        if (isValidCoordinate(minLat, minLng) && isValidCoordinate(maxLat, maxLng)) {
            const centerLat = (minLat + maxLat) / 2;
            const centerLng = (minLng + maxLng) / 2;
            
            // Set center
            map.setCenter(new f4.map.LatLng(centerLat, centerLng));
            
            // Calculate appropriate zoom level
            const latDiff = Math.abs(maxLat - minLat);
            const lngDiff = Math.abs(maxLng - minLng);
            const maxDiff = Math.max(latDiff, lngDiff);
            
            // Adjust zoom based on the spread of markers
            let zoom = Math.floor(Math.log2(360 / maxDiff)) + 1;
            zoom = Math.min(Math.max(zoom, 5), 15); // Clamp between 5 and 15
            map.setZoom(zoom);
        }
        
        cluster.value = new MarkerClusterer(map, newMarkers, {
            imagePath: `${urls.theme}/assets/images/cluster`,
            styles: [
                {
                    url: urls.theme + "/assets/images/cluster.png",
                    height: 24,
                    width: 24,
                    textColor: "#FFFFFF",
                },
                {
                    url: urls.theme + "/assets/images/cluster.png",
                    height: 24,
                    width: 24,
                    textColor: "#FFFFFF",
                },
                {
                    url: urls.theme + "/assets/images/cluster.png",
                    height: 24,
                    width: 24,
                    textColor: "#FFFFFF",
                },
                {
                    url: urls.theme + "/assets/images/cluster.png",
                    height: 24,
                    width: 24,
                    textColor: "#FFFFFF",
                },
                {
                    url: urls.theme + "/assets/images/cluster.png",
                    height: 24,
                    width: 24,
                    textColor: "#FFFFFF",
                },
            ],
            minimumClusterSize: 5,
            gridSize: 40,
            maxZoom: 15,
        });
        
        loadingMap.value = false;
    } else {
        console.warn('No valid markers found');
        loadingMap.value = false;
    }
}

async function initMap() {
    try {
        map.value = await kimono.addMap({
            container: "#map_box_search",
            zoom: 6,
            theta: 55,
            phi: 5,
            lon: 2.333333,
            lat: 48.866667,
        })

        mapInfoWindow.value = new f4.map.InfoWindow({
            content: "",
            closeButton: false,
            zIndex: 100,
        })

        map.value.on('load', async (realEstateAd, mapElement) => {
            mapObject.value = mapElement
            isMapInitialized.value = true

            if(computedPrograms.value?.length) {
                await setMarkers(mapElement)
            }
        })

    } catch (error) {
        console.error('Error initializing map:', error);
        retries.value++;
        if(retries.value < 4) {
            setTimeout(() => {
                initMap()
            }, 200)
        }
    }
}

watch(() => props.displayedComponent, async (newVal, oldVal) => {
    if(map.value) {
        window.setTimeout(() => {
            f4.map.event.trigger(map.value, 'resize')
        }, 300)
    }
}, { deep: true });

watch(
    () => props.programs,
    async (newVal, oldVal) => {
        if (newVal && newVal !== oldVal && isMapInitialized.value && mapObject.value) {
            await setMarkers(toRaw(mapObject.value));
        }
    },
    { deep: true }
);

onMounted(() => {
    if (!isMapInitialized.value && props.programs.length) {
        initMap();
    }
});
</script>

<template>
    <!-- <div v-if="loadingMap" class="absolute inset-0 mx-auto animate-pulse">Chargement...</div> -->
    <div class="w-auto h-full">
        <div class="w-full h-full" id="map_box_search" ref="map_box_search"></div>
    </div>
</template>

<style>
#map_box_search {
    width: 100%;
}

.kimono-map .f4map {
    width: 100%;
}

.cluster {
    font-size: 0.7rem;
    display: grid;
    line-height: 0;
    place-items: center;
}
</style>