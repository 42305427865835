
<script setup lang="ts">
import { onMounted, onUnmounted, ref, computed, watch, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';
import { useHead } from '@unhead/vue';

import { useSearchForm } from '@/composables/useSearchForm';
import { useLoadingState } from '@/composables/useLoadingState';

import { useSearchFormStore } from '@/stores/searchFormStore';
const searchStore = useSearchFormStore();
const { formPayload, userLocation, searchPagination, searchQuery } = storeToRefs(searchStore);

// HELPERS
import { formatSearchResults } from '@/utils/format';

// COMPONENTS
import ResultCard from '@/vue/program/ResultCard.vue';
import ResultSkeletonCard from '@/vue/program/ResultSkeletonCard.vue';
import SearchMap from '@/vue/SearchMap.vue';
import PlacesField from '@/vue/search/PlacesField.vue';
import TypesField from '@/vue/search/TypesField.vue';
import PriceField from '@/vue/search/PriceField.vue';
import RoomsField from '@/vue/search/RoomsField.vue';
import FiscalField from '@/vue/search/FiscalField.vue';
import AvancementField from '@/vue/search/AvancementField.vue';
import searchFormHomeMobile from '@/vue/forms/SearchFormHomeMobile.vue';
import SimilarSearchPrograms from '@/vue/program/SimilarSearchPrograms.vue';

// STATE
const details = ref<string>('')
const displayDetails = ref<boolean>(false)
const showMoreFields = ref<boolean>(false);
const programs = ref([] as Program[]);
const similarPrograms = ref([] as Program[]);
const isLocationSearch = ref(false);
const departmentTitle = ref('');
const isInitialLoad = ref(true);
const displayPlacesField = ref(true);
const formIsOpen = ref(false);
const displayMap = ref(false);
const displayedComponent = ref<string>("all");
const isMobile = ref<boolean>(false);
const currentZipCode = ref<string>('');
const allMapPrograms = ref([] as Program[]);
const isDepartment = ref<boolean>(false)

const offer = ref({
    title: '' as string,
    heading: '' as string,
    line1: '' as string,
    line2: '' as string,
});

// COMPUTED
const computedPageTitle = computed(() => {
    const department = formPayload.value?.places[0]?.value;
    const typology =
        (formPayload.value?.types?.includes('Maison') && formPayload.value?.types?.length === 1)
            ? 'maison neuve'
                : (formPayload.value?.types?.includes('Appartement') && formPayload.value?.types?.length === 1)
                    ? 'appartement neuf' : 'immobilier neuf';
    return `Achat ${typology} ${departmentTitle.value || department || ''}`;
});

const isFormDefault = computed(() => {
    const dataset = document.getElementById('search-results')?.dataset;

    const isOnlyDepartmentFilter = searchStore.isLocationSearch && 
        formPayload.value.places.length === 1 && 
        dataset?.department &&
        formPayload.value.types.length === 0 &&
        formPayload.value.price === "" &&
        formPayload.value.rooms.length === 0 &&
        formPayload.value.dispositifs.length === 0 &&
        formPayload.value.status.length === 0;

    return isOnlyDepartmentFilter || (
        formPayload.value.places.length === 0 &&
        formPayload.value.types.length === 0 &&
        formPayload.value.price === "" &&
        formPayload.value.rooms.length === 0 &&
        formPayload.value.dispositifs.length === 0 &&
        formPayload.value.status.length === 0
    );
});

const mapPrograms = computed(() => {
    // Only show all department programs when in default state
    if (isFormDefault.value && allMapPrograms.value.length) {
        return allMapPrograms.value;
    }

    // Show filtered results when any filter is applied
    if (programs.value?.length && isDepartment.value) {
        return allMapPrograms.value;
    }

    // Show filtered results when any filter is applied
    if (programs.value?.length && !isDepartment.value) {
        return programs.value;
    }
    
    // Show similar programs if no results and we have similar ones
    if (!programs.value?.length && similarPrograms.value?.length) {
        return similarPrograms.value;
    }
    
    // Fallback to all department programs
    return allMapPrograms.value || [];
});

const computedDetails = computed(() => {
    return details.value;
})

// COMPOSABLES
const {
    getProgramsForMap,
    getProgramsFromSearch,
    getLatLongFromLocation,
} = useSearchForm();

const { isLoading, startRequest, endRequest } = useLoadingState(200)

//SEO
useHead({
    title: computedPageTitle.value && `${computedPageTitle.value} ${currentZipCode.value ? currentZipCode.value?.slice(0, 2) : ''} | Les Nouveaux Constructeurs`,
    meta: [
        {
            name: 'description',
            content: () => `${new Date().getFullYear()} - ${computedPageTitle.value} : consultez toutes les annonces immobilières Les Nouveaux Constructeurs correspondant à votre recherche.`
        },
    ],
})

// METHODS
const handleDisplayDistanceSlider = () => {
    if(formPayload.value.places.length === 1) {
        displayPlacesField.value = true;
    } else {
        displayPlacesField.value = false;
    }
}

const handleDisplaySearchForm = () => {
    formIsOpen.value = !formIsOpen.value;
}

const updateDistance = (event: Event) => {
    const target = event.target as HTMLInputElement;
    userLocation.value.distance = parseInt(target.value);
    searchStore.updateSearchQuery(true);
};

const loadPrograms = async () => {
    startRequest();
    try {
        const response = await getProgramsFromSearch();

        if (!response) throw new Error('Error loading programs');

        programs.value = response.programs?.programs;
        similarPrograms.value = response.programs?.similar_programs;

        // Update pagination values
        searchPagination.value = response.pagination;
        searchPagination.value.currentPage = searchPagination.value?.currentPage;

        // Handle offers

        if (!!response?.offers?.length) {
            for (let i = 0; i < response.offers.length; i++) {
                if (!!response.offers[i]) {
                    offer.value = response.offers[i];
                    break;
                }
            }
        }

    } catch (error) {
        console.error(error);
    } finally {
        endRequest()
    }
};

const loadMorePrograms = async () => {
    if (searchPagination.value.currentPage >= searchPagination.value.maxPage) {
        return
    }

    startRequest()
    try {
        searchPagination.value.currentPage++
        searchStore.updateSearchQuery(displayPlacesField.value)

        const response = await getProgramsFromSearch()
        if (response?.programs) {
            programs.value = [...programs.value, ...response.programs.programs]
        }
    } finally {
        endRequest()
    }
}

const handleDefaultPrograms = async (departmentCode?: string) => {
    try {
        const response = await getProgramsForMap(departmentCode);
        if (response?.data?.length) {
            // console.log('Default programs:', response.data);
            allMapPrograms.value = response.data;
        }
    } catch (error) {
        console.error('Error loading default programs:', error);
    }
};

const resetFilters = async () => {
    const baseUrl = `${window.location.origin}/immobilier-neuf/`;

    // Reset all flags
    searchStore.isLocationSearch = false;
    displayDetails.value = false;
    displayPlacesField.value = false;
    isDepartment.value = false;
    departmentTitle.value = '';
    currentZipCode.value = '';

    // Reset the form payload
    searchStore.resetFormPayload();

    // Update URL
    window.history.pushState({}, '', baseUrl);

    // Load default programs instead of searching
    await handleDefaultPrograms();
};

const handleDisplayMobileView = () => {
    displayMap.value = !displayMap.value;
    handleDisplayComponents(displayMap.value ? 'map' : 'list');
}

const handleDisplayComponents = (component: string) => {
    displayedComponent.value = component;
}

const handleLocationUpdate = async (coordinates: { latitude: number, longitude: number }) => {
    userLocation.value = {
        ...userLocation.value,
        latitude: coordinates.latitude ?? null,
        longitude: coordinates.longitude ?? null
    };

    // Update search with new coordinates
    searchStore.updateSearchQuery(true);
    await loadPrograms();
}

const setLatLongInStore = (data: any) => {
    userLocation.value.latitude = data.latitude;
    userLocation.value.longitude = data.longitude;
}

const formatTitleUppercase = (title: string) => {
    return title.charAt(0).toUpperCase() + title.slice(1);
}

// Handle reset search when coming from department / city page
window.addEventListener('popstate', () => {
    const currentPath = window.location.pathname;
    if (currentPath === '/immobilier-neuf/') {
        searchStore.isLocationSearch = false;
        searchStore.resetFormPayload();
    } else {
        searchStore.syncFromUrl();
    }
});

// LIFECYCLE
onBeforeMount(async () => {
    const dataset = document.getElementById('search-results')?.dataset;

    // Handle location-specific searches
    if (dataset?.department || dataset?.city) {
        searchStore.isLocationSearch = true;
        displayPlacesField.value = false;
        
        let searchValue = ''
        let locationName = '';
        let locationType: 'department' | 'city' = 'department';

        if (dataset.city) {
            searchValue = dataset.city
            const city = window.location.pathname.split('/')[3];
            if (city) {
                locationName = city;
                locationType = 'city';
                departmentTitle.value = formatTitleUppercase(city);
            }
        } else if (dataset.department) {
            searchValue = dataset.department;
            const department = window.location.pathname.split('/')[2];
            if (department) {
                locationName = department.slice(0, -3);
                departmentTitle.value = formatTitleUppercase(locationName);
                // Load all programs for this department in the map
                await handleDefaultPrograms(dataset.department);
            }
        }

        if (locationName) {
            await searchStore.searchByLocation(locationType, searchValue as string);

            try {
                const { data } = await getLatLongFromLocation(locationName);
                if (data) {
                    await handleLocationUpdate(data);
                }
            } catch (error) {
                console.error(`Error getting ${locationType} location:`, error);
                await loadPrograms();
            }
        }
    } else {
        if(formPayload.value.places.length === 1) {
            const type = formPayload.value.places[0].type;
            const department = formPayload.value?.places[0]?.value?.split(' (')[1]?.slice(0, -1);
            if(type && department) {
                if (type === "department") {
                    isDepartment.value = true;
                    await handleDefaultPrograms(department);
                }
            } else {
                await handleDefaultPrograms();
            }
        } else {
            await handleDefaultPrograms();
        }

        // Handle non-location searches
        searchStore.syncFromUrl();
        searchStore.updateSearchQuery();
        await loadPrograms();
    }

    // Handle other initializations
    if (dataset?.similar) {
        similarPrograms.value = JSON.parse(dataset.similar);
    }

    if (dataset?.details) {
        details.value = dataset.details;
        displayDetails.value = true;
    }

    // Check display for form inputs
    if (formPayload.value.rooms.length ||
        formPayload.value.dispositifs.length ||
        formPayload.value.status.length) {
        showMoreFields.value = true;
    }

    // Handle single place selection
    if (formPayload.value.places.length === 1) {
        const place = formPayload.value.places[0].value.split(' (')[0]?.toLowerCase();
        if (place) {
            try {
                const { data } = await getLatLongFromLocation(place);
                if (data) {
                    setLatLongInStore(data);
                    if (data.postcode) {
                        currentZipCode.value = data.postcode.slice(0, 2);
                    }
                }
            } catch (error) {
                console.error('Error getting place coordinates:', error);
                // Continue without location data
            }
        }
    }

    isInitialLoad.value = false;
});

onMounted(async () => {
    isMobile.value = window.innerWidth < 768;

    window.addEventListener('resize', () => {
        isMobile.value = window.innerWidth < 768;
    });

    handleDisplayDistanceSlider();

    // Listen to browser back/forward buttons
    window.addEventListener('popstate', () => {
        searchStore.syncFromUrl();
    });
});

onUnmounted(() => {
    isLocationSearch.value = false;
});

watch(() => formPayload.value, async () => {
    if (!isInitialLoad.value) {
        searchStore.resetPagination();

        if (searchStore.isLocationSearch) {
            searchStore.updateSearchQuery(false, false);
            
            // If we're only filtering by department, reload all department programs for the map
            const dataset = document.getElementById('search-results')?.dataset;
            if (isFormDefault.value && dataset?.department) {
                await handleDefaultPrograms(dataset.department);
            }
        } else {
            isDepartment.value = false;
            if(formPayload.value.places.length === 1) {
                const type = formPayload.value.places[0].type;
                const department = formPayload.value.places[0].value.split(' (')[1].slice(0, -1);
                if(type && department) {
                    if (type === "department") {
                        isDepartment.value = true;
                        await handleDefaultPrograms(department);
                    }
                } else {
                    searchStore.updateSearchQuery(true);
                }
            } else {
                searchStore.updateSearchQuery(displayPlacesField.value);
            }
        }

        loadPrograms();
    }
}, { deep: true });

watch(
    () => searchQuery.value,
    () => {
        handleDisplayDistanceSlider();
}, {deep: true});

</script>

<template>
    <!-- This is for SEO purposes -->
    <div>
        <!-- search -->
        <div class="bg-white p-4 lg:p-6">
            <div class="max-md:hidden xl:max-w-screen-xl 2xl:max-w-screen-2xl w-full mx-auto flex items-start gap-4">
                <div class="w-full relative z-50 space-y-2">
                    <div class="w-full grid grid-cols-3 gap-x-4 gap-y-2">
                        <PlacesField
                            v-model="formPayload.places"
                            @location-updated="handleLocationUpdate"
                        />
                        <TypesField
                            v-model="formPayload.types"
                        />
                        <PriceField
                            v-model="formPayload.price"
                        />
                        <RoomsField
                            v-model="formPayload.rooms"
                            v-show="showMoreFields"
                        />
                        <FiscalField
                            v-model="formPayload.dispositifs"
                            v-show="showMoreFields"
                        />
                        <AvancementField
                            v-model="formPayload.status"
                            v-show="showMoreFields"
                        />
                    </div>
                    <div class="flex items-center gap-6">
                        <button class="inline-flex items-center gap-1 p-1 pl-2 bg-secondary uppercase text-white rounded" @click="showMoreFields = !showMoreFields">
                            {{ showMoreFields ? 'Moins' : 'Plus' }} de filtres
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                            </svg>
                        </button>
                        <button class="text-primary flex gap-2 items-center" @click="resetFilters">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                <path d="M11.9969 0C12.393 0.1353 12.6497 0.394386 12.6746 0.856062C12.6977 1.28607 12.7185 1.71608 12.7393 2.14645C12.7693 2.77366 12.7976 3.40122 12.8279 4.02842C12.8521 4.53184 12.5587 4.92587 12.0835 4.96365C11.3152 5.02446 10.5449 5.05541 9.77529 5.09823C9.51488 5.11262 9.25481 5.1299 8.99406 5.13709C8.51278 5.15005 8.14023 4.79129 8.12421 4.30694C8.10819 3.83555 8.45518 3.44764 8.92657 3.41705C9.23743 3.3969 9.5493 3.38719 9.8605 3.37063C9.97128 3.36488 10.0821 3.35336 10.2348 3.34113C9.81414 3.02878 9.3966 2.81144 8.95077 2.65419C5.88006 1.57215 2.67334 3.64447 2.17741 7.02986C1.74589 9.97552 3.7494 12.8147 6.54539 13.2195C8.79057 13.5448 10.8827 12.3937 11.9168 10.2645C12.1135 9.85965 12.4322 9.65454 12.7809 9.70888C13.359 9.79848 13.6654 10.4055 13.4081 10.9651C13.0461 11.7535 12.5638 12.4523 11.955 13.0478C10.5763 14.3965 8.94157 15.05 7.06417 14.9975C3.88302 14.9086 1.12793 12.3785 0.600295 9.07807C0.00721624 5.36631 2.09492 1.87621 5.52694 0.894925C7.41524 0.354803 9.20743 0.654191 10.8827 1.72328C10.9393 1.75962 10.9959 1.79597 11.0678 1.84239C11.0678 1.68334 11.0702 1.54336 11.0671 1.40374C11.0651 1.30802 11.0535 1.21266 11.0494 1.11731C11.0225 0.462396 11.1615 0.232457 11.7249 0H11.9969V0Z" fill="#640A32"/>
                            </svg>
                            <p class="border-b border-primary">
                                Réinitialiser la recherche
                            </p>
                        </button>
                    </div>
                </div>

                <div class="flex">
                    <button
                        type="button"
                        class="p-5 bg-primary text-white font-extrabold text-base uppercase leading-none rounded-sm inline-flex items-center gap-2"
                        @click="loadPrograms"
                    >
                        <span class="whitespace-nowrap" :class="isLoading && 'animate-pulse'">
                            {{ isLoading ? 'Chargement' : formatSearchResults(searchPagination?.totalCount) }}
                        </span>

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="md:hidden flex justify-between items-center">
                <button
                    @click="handleDisplaySearchForm"
                    class="px-2 lg:px-3 py-2 inline-flex items-center gap-2 text-sm text-white bg-red uppercase rounded"
                >
                    Modifier ma recherche
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                </button>
                <div class="flex gap-2 lg:gap-4">
                    <p class="uppercase lg:text-lg"
                    :class="displayMap && 'font-bold'"
                    >carte</p>
                    <div class="flex h-6 w-12 bg-gray-200 rounded-full transition transform duration-600" @click="handleDisplayMobileView">
                        <span
                            class="h-6 z-100 rounded-full w-6 bg-primary"
                            :class="displayMap ? 'translate-x-0' : 'translate-x-6'"
                        ></span>
                    </div>
                    <p class="uppercase lg:text-lg"
                    :class="!displayMap && 'font-bold'"
                    >liste</p>
                </div>

                <searchFormHomeMobile
                    v-if="isMobile"
                    :formIsOpen="formIsOpen"
                    @handleDisplaySearchForm="handleDisplaySearchForm"
                />
            </div>
        </div>

        <div class="w-full mx-auto">
            <div class="grid"
                :class="displayedComponent === 'all' ? 'lg:grid-cols-2' : 'lg:grid-cols-1'">
                <!-- col-1 -->
                <div
                    class="p-6 flex flex-col gap-5"
                    :class="[
                        displayMap && 'max-lg:hidden',
                    ]"
                >
                    <h1 class="text-sm text-gray-400">
                        <span>
                            {{ computedPageTitle }}
                        </span>
                    </h1>
                    <div class="lg:flex lg:items-baseline lg:gap-2">
                        <div v-if="isLoading" class="font-regular text-[2rem] md:text-[2.375rem] text-secondary text-nowrap animate-pulse">
                            Chargement
                        </div>
                        <div v-else class="font-regular text-[2rem] md:text-[2.375rem] text-secondary text-nowrap">
                            {{ searchPagination?.totalCount ?? 0 }} résidence{{ searchPagination?.totalCount > 1 ? 's' : '' }}
                        </div>
                        <p v-show="!isLoading" class="font-middle text-base leading-tight text-nowrap grow">
                            {{ searchPagination?.totalCount > 1 ? 'correspondent' : 'correspond' }} à votre recherche
                        </p>
                        <div v-if="!isLoading" class="flex gap-1 max-md:hidden text-gray-400">
                            <button
                                @click="handleDisplayComponents('map')"
                                :class="displayedComponent === 'map' && 'text-red font-semibold border-b border-red'"
                            >
                                Carte
                            </button>
                            |
                            <button
                                @click="handleDisplayComponents('all')"
                                :class="displayedComponent === 'all' && 'text-red font-semibold border-b border-red'"
                            >
                                Mixte
                            </button>
                            |
                            <button
                                @click="handleDisplayComponents('list')"
                                :class="displayedComponent === 'list' && 'text-red font-semibold border-b border-red'"
                            >
                                Liste
                            </button>
                        </div>
                    </div>

                    <div v-if="displayPlacesField" class="rounded-md bg-white px-6 py-4">
                        <p class="mb-2">
                            Rechercher les résidences à proximité dans un rayon de <strong>{{ userLocation.distance }} km</strong>
                        </p>
                        <input
                            class="w-full search-slider accent-primary"
                            type="range"
                            step="5"
                            min="0"
                            max="150"
                            :value="userLocation.distance"
                            @input="updateDistance"
                            @change="loadPrograms"
                        >
                        <div class="flex items-center justify-between">
                            <div class="text-gray-400 font-light">0 km</div>
                            <div class="text-gray-400 font-light">150 km</div>
                        </div>
                    </div>

                    <!-- offer -->
                    <div v-if="offer.title && !isLoading" class="rounded-md bg-primary px-6 py-4 space-y-1">
                        <p v-if="offer.title" class="text-white uppercase text-lg">
                            {{ offer.title }}
                        </p>
                        <p class="text-tertiary font-bold text-2xl uppercase flex gap-2 flex-wrap">
                            <span v-if="offer.heading">
                                {{ offer.heading }}
                            </span>
                            <span v-if="offer.line1">
                                {{ offer.line1 }}
                            </span>
                            <span v-if="offer.line2">
                                {{ offer.line2 }}
                            </span>
                        </p>
                    </div>


                    <div
                        :class="[
                            displayedComponent === 'all' && 'lg:grid lg:gap-4 lg:grid-cols-2',
                            displayedComponent === 'list' && 'lg:grid lg:gap-4 lg:grid-cols-4',
                            displayedComponent === 'map' && 'hidden'
                        ]"
                    >
                        <template v-if="isLoading">
                            <ResultSkeletonCard v-for="item in 6" :key="item" />
                        </template>
                        <template v-else>
                            <template v-for="program in programs" :key="program.id">
                                <ResultCard :program="program" />
                            </template>

                            <p v-if="!!programs.length" class="text-center pt-4 text-primary col-span-2">
                                {{ programs.length }} résultats sur {{ searchPagination.totalCount }}
                            </p>

                            <div class="col-span-full p-6 flex items-center justify-center">
                                <button
                                    v-if="searchPagination.currentPage < searchPagination.maxPage"
                                    type="button"
                                    class="p-5 bg-primary text-white font-extrabold text-base uppercase leading-none rounded-sm inline-flex items-center gap-2"
                                    @click="loadMorePrograms"
                                >
                                    <span>
                                        Plus de résultats
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                    </svg>
                                </button>
                            </div>

                            <!-- Display details about department / city if there's any -->
                            <template v-if="displayDetails && computedDetails">
                                <div v-html="computedDetails" class="detailsPrograms col-span-full space-y-2 mb-8"></div>
                            </template>

                            <div v-if="programs.length < 9 && similarPrograms.length" class="flex flex-col col-span-full">
                                <SimilarSearchPrograms :programs="similarPrograms" :displayedComponent="displayedComponent" />
                            </div>

                        </template>
                    </div>
                </div>
                <!-- col-2 -->
                <div
                    class="w-full h-screen bg-white sticky top-0"
                    :class="[
                        !displayMap && 'max-lg:hidden',
                        displayedComponent === 'all' || displayedComponent === 'map' ? 'block' : 'hidden'
                    ]"
                >
                    <!-- Map -->
                    <template v-if="!!mapPrograms?.length">
                        <SearchMap
                            :programs="mapPrograms"
                            :displayedComponent="displayedComponent"
                        />
                    </template>
                    <template v-else>
                        <div class="w-full h-full bg-gray-300 animate-pulse duration-300"></div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.detailsPrograms a {
    color: #630A32;
    text-decoration: underline;
}
.detailsPrograms h2 {
    font-size: 2rem;
    font-weight: 400;
    text-transform: uppercase;
    color: #A40256;
    /* color: #630A32; */
}

.detailsPrograms h3 {
    font-size: 1.5rem;
    font-weight: 400;
}
</style>
