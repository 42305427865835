<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import axios from 'axios';
import { onClickOutside } from '@vueuse/core'
import { useSearchForm } from '@/composables/useSearchForm';
import { useSearchFormStore } from '@/stores/searchFormStore';
import { storeToRefs } from 'pinia';

const searchStore = useSearchFormStore();
const { userLocation } = storeToRefs(searchStore);
const { getLatLongFromLocation } = useSearchForm();

const model = defineModel({
    default: [] as ProgramPlace[],
});

const emit = defineEmits(['location-updated']);

const focus = ref(false);
const loading = ref(false);
const container = ref(null);
const search = ref('');
const results = ref([] as ProgramPlace[]);
const isUpdatingCoordinates = ref(false);
const searchTimeout = ref<number | null>(null);

const displayLabel = computed(() => {
    return [...model.value].splice(0, 1).map(item => item.value).join(', ') + (model.value.length > 1 ? ` et ${model.value.length - 1} de plus` : '');
});

const onSearch = async () => {
    try {
        loading.value = true;
        // @ts-expect-error
        const ajaxUrl = window?.urls?.ajax;
        const response = await axios.get(`${ajaxUrl}?action=search_autocomplete&search=${search.value}`)

        if(response.status !== 200) throw new Error('Error fetching data from autocomplete');
        results.value = response.data;
    } catch (error) {
        console.error(error);
    } finally {
        loading.value = false;
    }
}

const updateLocationCoordinates = async (location: string) => {
    try {
        isUpdatingCoordinates.value = true;
        const { data } = await getLatLongFromLocation(location);

        if (data) {
            userLocation.value.latitude = data.latitude;
            userLocation.value.longitude = data.longitude;
            emit('location-updated', { latitude: data.latitude, longitude: data.longitude });
        }
    } finally {
        isUpdatingCoordinates.value = false;
    }
}

const toggleItemOnModel = async (result: ProgramPlace) => {
    const isAdding = !model.value.find((item) => item.value === result.value);

    if (isAdding) {
        model.value = [...model.value, result];
        search.value = '';

        // Update coordinates immediately when selecting a location
        const locationName = result.value.split(' (')[0];
        await updateLocationCoordinates(locationName);
    } else {
        model.value = model.value.filter((item) => item.value !== result.value);

        if (model.value.length === 0) {
            userLocation.value.latitude = 48.8566;
            userLocation.value.longitude = 2.3522;
            emit('location-updated', { latitude: 48.8566, longitude: 2.3522 });
        }
    }
}

// Handle clicks outside the component
onClickOutside(container, () => {
    focus.value = false;
    search.value = '';
    results.value = [];
});

// Improved input handler with direct timeout management
const handleInput = (event: Event) => {
    const target = event.target as HTMLInputElement;
    search.value = target.value;
    
    // Clear any existing timeout
    if (searchTimeout.value) {
        clearTimeout(searchTimeout.value);
    }
    
    // Only search if we have at least one character and not updating coordinates
    if (search.value && !isUpdatingCoordinates.value) {
        searchTimeout.value = setTimeout(() => {
            onSearch();
        }, 500) as unknown as number;
    }
};
</script>

<template>
    <div ref="container" class="mt-3 flex flex-col relative">
        <div v-if="loading" class="absolute right-0 top-3">
            <svg class="animate-spin size-5 text-secondary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
        <label
            for="places"
            :class="`uppercase text-dark/60 absolute ${focus || search || model.length ? 'text-xs -top-3' : 'text-base top-2.5'} transition-all duration-150 ease-in-out pointer-events-none`"
        >
            Région, département, ville
        </label>
        <div v-if="!focus && model.length" class="w-full absolute py-2 pl-1 text-nowrap truncate pointer-events-none">
            {{ displayLabel }}
        </div>
        <input
            id="places"
            name="places"
            type="text"
            class="border-b-2 border-secondary py-2 pl-[4px] outline-none"
            @focus="focus = true"
            v-model="search"
            @input="handleInput"
            autocomplete="off"
        >
        <div v-if="focus && (results.length > 0 || model.length > 0)" class="w-full absolute top-11 z-60 bg-white shadow max-h-64 overflow-y-auto">
            <ul class="w-full px-4 divide-y divide-secondary">
                <li v-if="model.length" class="py-2.5 flex flex-wrap gap-1.5">
                    <span v-for="(item, index) in model" :key="index" class="inline-flex gap-0.5 pl-2 pr-1 bg-primary text-white rounded">
                        {{ item.value }}
                        <button type="button" @click.stop="toggleItemOnModel(item)">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </span>
                </li>
                <li v-for="(result, index) in results" :key="index" @click.stop="toggleItemOnModel(result)">
                    <div class="p-3 pl-7 relative flex items-center gap-3 cursor-pointer hover:bg-primary/10">
                        <svg v-if="model.find(item => item.value === result.value)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 text-primary absolute left-0">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                        </svg>
                        {{ result?.value }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>