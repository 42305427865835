import { ref } from 'vue';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { useSearchFormStore } from '@/stores/searchFormStore';
import { formatSearchResults } from '@/utils/format';
import { useMemoize } from '@vueuse/core';
export const useSearchForm = () => {
    const searchStore = useSearchFormStore();
    const { searchQuery, userLocation } = storeToRefs(searchStore);
    const loading = ref(false);

    const buildSearchParams = (includeLocation: boolean = true) => {
        const params = new URLSearchParams(searchQuery.value);
        
        const shouldIncludeLocation = includeLocation && 
            userLocation.value.latitude && 
            userLocation.value.longitude && 
            (searchStore.formPayload.places.length > 0 || searchStore.isLocationSearch);
    
        if (shouldIncludeLocation) {
            params.set('latitude', String(userLocation.value.latitude));
            params.set('longitude', String(userLocation.value.longitude));
            params.set('distance', String(userLocation.value.distance));
        }
    
        return params.toString();
    };

    const setSearchUrl = async (e?: Event) => {
        if (e) {
            e.preventDefault();
        }
        const searchParams = buildSearchParams();
        // Get count before navigation
        const response = await getProgramsFromSearch();
        if (response?.pagination) {
            // searchPagination.value = response.pagination;
        }
        window.location.href = `/immobilier-neuf/?${searchParams}`;
    };

    const getProgramsForMap = useMemoize(async (params?: string) => {
        try {
            loading.value = true;
            const response = await axios.get(`${import.meta.env.VITE_APP_URL}/wp-admin/admin-ajax.php`, {
                params: {
                    action: 'get_map_programs',
                    query: params,
                }
            });

            if(response.status !== 200) {
                throw new Error('Error while getting programs for map');
            }

            return response.data;

        } catch (error) {
            console.error(error);
        } finally {
            loading.value = false;
        }
    })

    const getProgramsFromSearch = async () => {
        try {
            loading.value = true;
            const searchParams = buildSearchParams();
            
            const response = await axios.get(
                `${import.meta.env.VITE_APP_URL}/wp-admin/admin-ajax.php`, {
                    params: {
                        action: 'search_programs',
                        ...Object.fromEntries(new URLSearchParams(searchParams))
                    }
                }
            );

            if (response.status !== 200) {
                throw new Error('Error while getting programs');
            }

            return {
                offers: response.data.offers,
                programs: response.data.programs,
                pagination: response.data.pagination,
            };
        } catch (error) {
            console.error('Error fetching programs:', error);
            return null;
        } finally {
            loading.value = false;
        }
    };

    const getLatLongFromLocation = async (location: string) => {
        try {
            const response = await axios.get(
                `${import.meta.env.VITE_APP_URL}/wp-admin/admin-ajax.php`, {
                    params: {
                        action: 'get_coordinates',
                        localisation: location,
                    },
                }
            );

            if (response.status !== 200) {
                throw new Error('Error while fetching coordinates');
            }

            return response.data;
        } catch (error) {
            console.error('Error fetching coordinates:', error);
            return null;
        }
    };

    return {
        loading,
        setSearchUrl,
        getProgramsForMap,
        getProgramsFromSearch,
        getLatLongFromLocation,
        formatSearchResults
    };
};