import { handleFormSubmit, handleClickOutsideForm } from '@/utils/forms';
import { useGtm } from '@/composables/useGtm';

document.addEventListener("DOMContentLoaded", function () {
    const formModalContainer: HTMLElement | null = document.querySelector("#contact-form");
    const closeButton: HTMLElement | null = document.querySelector(".closeContactForm");
    const triggers: NodeListOf<HTMLElement> = document.querySelectorAll('.openContactForm');
    const submitButton: HTMLElement | null = document.querySelector(".submitContactForm");
    let formPosition: string | 'Unspecified';

    let hasListernerSetup = false;

    triggers?.forEach(item => {
        item?.addEventListener('click', (event) => {
            event.stopPropagation(); // Prevent the click from propagating to the window
            if (formModalContainer) {
                formModalContainer.classList.toggle('hidden');
            }
            
            formPosition = item?.getAttribute('data-position') || 'Unspecified';
            
            if(formModalContainer && !formModalContainer.classList.contains('hidden') && !hasListernerSetup) {
                hasListernerSetup = true;
                handleClickOutsideForm(formModalContainer);
                useGtm('click_info_request');
            }
        })
    });

    closeButton?.addEventListener('click', () => {
        if (formModalContainer) {
            formModalContainer.classList.add('hidden');
        }
    });

    formModalContainer?.addEventListener('submit', (event) => {
        event.preventDefault();
        
        const formData = new FormData(event.target as HTMLFormElement);
        handleFormSubmit(formData, submitButton, 'contact-form', 'contact');

        if(formPosition === 'espace-vente') {
            useGtm('submit_land_sale', 'espace-vente');
        } else {
            useGtm('submit_info_request', formPosition)
        }
    });
});