<template>
    <div class="flex flex-col lg:flex-row">
        <div class="w-full lg:w-1/3 lg:pr-8">
            <div class="px-6 py-4 bg-white rounded-xl shadow-md">
                <div class="" v-for="(topic, index) in topics" :key="index">
                    <div
                        class="flex px-4 py-2 mb-4 font-sans text-lg rounded-lg no-underline cursor-pointer"
                        :class="{
                            'text-white bg-plum':
                                current_topic.name === topic.name,
                            'text-black bg-white':
                                current_topic.name !== topic.name,
                        }"
                        @click="selectTopic(topic)"
                        v-html="topic.name"
                    ></div>
                </div>
            </div>
        </div>
        <div class="w-full lg:w-2/3 pt-8 lg:pt-0" id="questions">
            <div
                class="mb-4 bg-white rounded-xl shadow-md"
                v-for="(question, index) in current_topic.questions"
                :key="index"
            >
                <div
                    class="flex flex-row justify-between items-center px-6 py-3 rounded-xl shadow-sm cursor-pointer"
                    :class="{
                        'bg-plum text-white':
                            current_question.name === question.name,
                        'bg-white text-plum hover:bg-plum hover:text-white':
                            current_question.name !== question.name,
                    }"
                    @click="selectQuestion(question)"
                >
                    <div class="font-sans font-medium text-lg leading-normal">
                        {{ question.name }}
                    </div>
                    <div
                        class="flex justify-center items-center ml-3"
                        :class="{
                            'rotate-180':
                                current_question.name === question.name,
                        }"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    </div>
                </div>
                <div
                    :class="{
                        'block px-6 pb-6':
                            current_question.name === question.name,
                        'hidden px-6 pb-6':
                            current_question.name !== question.name,
                    }"
                >
                    <div
                        class="text-left font-sans"
                        v-html="question.content"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script >
import axios from "axios";

export default {
    name: "FAQ",

    // props: ["topics"],

    data() {
        return {
            current_topic: {},
            current_question: {},
            topics: []
        };
    },
    methods: {
        selectTopic(topic) {
            this.current_topic = topic;
            this.current_question = {};

            if (window.innerWidth < 992) {
                document
                    .getElementById("questions")
                    .scrollIntoView({ behavior: "smooth" });
            }
        },

        selectQuestion(question) {
            if (this.current_question.name === question.name) {
                this.current_question = {};

                return;
            }

            this.current_question = question;
        },

        async getFaqTopics() {
            try {
                const response = await axios
                    .get(`${import.meta.env.VITE_APP_URL}/wp-admin/admin-ajax.php?action=get_topics`);
    
                    if(response.status !== 200 || !response.data) throw new Error('Error fetching topics');
                // return response;
                this.topics = response.data;
                this.current_topic = this.topics[0];
                
            } catch (error) {
                console.error(error);
            }
        },
    },

    async mounted() {
        await this.getFaqTopics();
    },
};
</script>
