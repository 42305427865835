<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia';
import axios from 'axios';
// HELPERS
import { useSearchForm } from '@/composables/useSearchForm';
// COMPONENTS
import PlacesField from '@/vue/search/PlacesField.vue';
import TypesField from '@/vue/search/TypesField.vue';
import PriceField from '@/vue/search/PriceField.vue';
import RoomsField from '@/vue/search/RoomsField.vue';
import FiscalField from '@/vue/search/FiscalField.vue';
import AvancementField from '@/vue/search/AvancementField.vue';

// STORES
import { useSearchFormStore } from '@/stores/searchFormStore';
const searchStore = useSearchFormStore();
const { formPayload, searchQuery, searchPagination } = storeToRefs(searchStore);

const {
    formatSearchResults,
    getProgramsFromSearch
} = useSearchForm();

defineProps({
    formIsOpen: {
        type: Boolean,
        default: false
    }
})

const emits = defineEmits(['handleDisplaySearchForm'])

const allFiltersShown = ref<Boolean>(false)

const fetchInitialCount = async () => {
    try {
        const response = await axios.get(`${import.meta.env.VITE_APP_URL}/wp-admin/admin-ajax.php`, {
            params: {
                action: 'search_programs_count',
            }
        }); 

        searchPagination.value = {
            totalCount: response.data?.programs_count || 0,
            maxPage: 1,
            currentPage: 1,
        }

    } catch (error) {
        console.error('Error fetching initial count:', error);
    }
};

const getProgramsCount = async () => {
    try {
        const response = await getProgramsFromSearch();
        if (response?.pagination) {
            searchPagination.value = response.pagination;
        }
    } catch (error) {
        console.error('Error fetching initial count:', error);
    }
};


const getPrograms = async () => {
    window.location.href = `/immobilier-neuf/?${searchQuery.value}`;
};

// METHODS
const resetFilters = () => {
    searchStore.resetFormPayload();
};

const displayMoreFilters = () => {
    allFiltersShown.value = !allFiltersShown.value
}

onMounted(async () => {
    await fetchInitialCount();
})

watch(() => formPayload.value, async () => {
    // Only update URL if not a location search
    if (!searchStore.isLocationSearch) {
        searchStore.updateSearchQuery();
        await getProgramsCount();
    }
}, { deep: true });
</script>

<template>
    <div
        v-show="formIsOpen"
        id="homepageSearchFormMobile"
        @submit.prevent="getPrograms"
        class="fixed inset-0 bg-white z-[999] p-4 overflow-y-scroll"
    >
        <button
            @click="emits('handleDisplaySearchForm')"
            class="absolute top-4 right-4"
            type="button"
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                <path d="M25.8112 9.20532L9.00787 26.0086" stroke="#640A32" stroke-width="2"/>
                <path d="M9.00848 9.20532L25.8118 26.0086" stroke="#640A32" stroke-width="2"/>
            </svg>
        </button>

        <h3 class="text-primary uppercase text-3xl text-center mt-2">
            Recherchez <br />votre logement
        </h3>

        <form @submit.prevent="getPrograms" class="flex flex-col w-full items-center gap-4 p-6">
            <PlacesField
                class="w-full"
                v-model="formPayload.places"
            />
            <TypesField
                class="w-full"
                v-model="formPayload.types"
                />
            <PriceField
                class="w-full"
                v-model="formPayload.price"
            />

            <div v-if="allFiltersShown" class="flex flex-col gap-4 w-full">
                <RoomsField
                    class="w-full"
                    v-model="formPayload.rooms"
                />

                <FiscalField
                    class="w-full"
                    v-model="formPayload.dispositifs"
                />

                <AvancementField
                    class="w-full"
                    v-model="formPayload.status"
                />
            </div>


            <button
                @click="displayMoreFilters"
                type="button"
                class="w-fit p-2 bg-red text-white font-regular text-base uppercase leading-none rounded-md inline-flex items-center gap-2"
            >
                <span class="btnContent">
                    {{ allFiltersShown ? 'Moins' : 'Plus' }} de filtres
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>
            </button>

            <button class="text-primary flex gap-2 items-center" @click="resetFilters">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                    <path d="M11.9969 0C12.393 0.1353 12.6497 0.394386 12.6746 0.856062C12.6977 1.28607 12.7185 1.71608 12.7393 2.14645C12.7693 2.77366 12.7976 3.40122 12.8279 4.02842C12.8521 4.53184 12.5587 4.92587 12.0835 4.96365C11.3152 5.02446 10.5449 5.05541 9.77529 5.09823C9.51488 5.11262 9.25481 5.1299 8.99406 5.13709C8.51278 5.15005 8.14023 4.79129 8.12421 4.30694C8.10819 3.83555 8.45518 3.44764 8.92657 3.41705C9.23743 3.3969 9.5493 3.38719 9.8605 3.37063C9.97128 3.36488 10.0821 3.35336 10.2348 3.34113C9.81414 3.02878 9.3966 2.81144 8.95077 2.65419C5.88006 1.57215 2.67334 3.64447 2.17741 7.02986C1.74589 9.97552 3.7494 12.8147 6.54539 13.2195C8.79057 13.5448 10.8827 12.3937 11.9168 10.2645C12.1135 9.85965 12.4322 9.65454 12.7809 9.70888C13.359 9.79848 13.6654 10.4055 13.4081 10.9651C13.0461 11.7535 12.5638 12.4523 11.955 13.0478C10.5763 14.3965 8.94157 15.05 7.06417 14.9975C3.88302 14.9086 1.12793 12.3785 0.600295 9.07807C0.00721624 5.36631 2.09492 1.87621 5.52694 0.894925C7.41524 0.354803 9.20743 0.654191 10.8827 1.72328C10.9393 1.75962 10.9959 1.79597 11.0678 1.84239C11.0678 1.68334 11.0702 1.54336 11.0671 1.40374C11.0651 1.30802 11.0535 1.21266 11.0494 1.11731C11.0225 0.462396 11.1615 0.232457 11.7249 0H11.9969V0Z" fill="#640A32"/>
                </svg>
                <p class="border-b border-primary">
                    Réinitialiser la recherche
                </p>
            </button>

            <div class="flex">
                <button
                    type="submit"
                    class="p-5 bg-primary text-white font-extrabold text-base uppercase leading-none rounded-sm inline-flex items-center gap-2"
                    @submit.prevent="getPrograms"
                >
                        <span class="whitespace-nowrap">
                            {{ formatSearchResults(searchPagination?.totalCount) }}
                        </span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                </button>
            </div>
        </form>
    </div>
</template>
