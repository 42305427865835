export const useGtm = (event: string, position?: string) => {
    // console.log('dataLayer', window.dataLayer);
    switch (event) {
        // téléchargement plaquette
        case 'download_plaquette': {
            window.dataLayer.push({
                'event': 'click_button',
                'clickText': 'telechargement_plaquette',
            });
            break;
        }
        case 'submit_download_plaquette': {
            window.dataLayer.push({
                'event': 'formSubmission',
                'formType': 'telechargement_plaquette',
                'formPosition': position,
            });
            break;
        }
        
        // téléchargement plans
        case 'download_plan': {
            window.dataLayer.push({
                'event': 'click_button',
                'clickText': 'telechargement_plan',
            });
            break;
        }
        case 'submit_download_plan': {
            window.dataLayer.push({
                'event': 'formSubmission',
                'formType': 'telechargement_plan',
                'formPosition': 'gallery',
            });
            break;
        }
        
        // formulaire être rappelé
        case 'click_callback': {
            window.dataLayer.push({
                'event': 'click_button',
                'clickText': 'etre_rappele',
            });
            break;
        }
        case 'submit_callback': {
            window.dataLayer.push({
                'event': 'formSubmission',
                'formType': 'etre_rappele',
                'formPosition': 'widget'
            });
            break;
        }
        
        // formulaire demande d'informations
        case 'click_info_request': {
            window.dataLayer.push({
                'event': 'click_button',
                'clickText': 'demande_informations',
            });
            break;
        }
        case 'submit_info_request': {
            window.dataLayer.push({
                'event': 'formSubmission',
                'formType': 'demande_informations',
                'formPosition': position
            });
            break;
        }
        
        // vidéo
        case 'click_video': {
            window.dataLayer.push({
                'event': 'click_button',
                'clickText': 'video',
            });
            break;
        }
        
        // galerie photos
        case 'click_gallery': {
            window.dataLayer.push({
                'event': 'click_button',
                'clickText': 'gallerie_photo',
            });
            break;
        }
        
        // maquette 3d
        case 'click_3d_model': {
            window.dataLayer.push({
                'event': 'click_button',
                'clickText': 'maquette_3d',
            });
            break;
        }
        
        // plan 3d
        case 'click_3d_plan': {
            window.dataLayer.push({
                'event': 'click_button',
                'clickText': 'plan_3d',
            });
            break;
        }
        
        // visite virtuelle
        case 'click_virtual_tour': {
            window.dataLayer.push({
                'event': 'click_button',
                'clickText': 'visite_virtuelle',
            });
            break;
        }
        
        // formulaire je prends rendez-vous
        case 'click_appointment': {
            window.dataLayer.push({
                'event': 'click_button',
                'clickText': 'prise_de_rdv',
            });
            break;
        }
        case 'submit_appointment': {
            window.dataLayer.push({
                'event': 'formSubmission',
                'formType': 'prise_de_rdv',
                'formPosition': 'Footer/Header...'
            });
            break;
        }
        
        // formulaire terrain à vendre
        case 'click_land_sale': {
            window.dataLayer.push({
                'event': 'click_button',
                'clickText': 'terrain_a_vendre',
            });
            break;
        }
        case 'submit_land_sale': {
            window.dataLayer.push({
                'event': 'formSubmission',
                'formType': 'terrain_a_vendre',
                // With their custom menu, we can't set the form position like we should so we default as the header menu item.
                'formPosition': position ?? 'header'
            });
            break;
        }
        
        // formulaire parrainage
        case 'click_referral': {
            window.dataLayer.push({
                'event': 'click_button',
                'clickText': 'parrainage',
            });
            break;
        }
        case 'submit_referral': {
            window.dataLayer.push({
                'event': 'formSubmission',
                'formType': 'parrainage',
                'formPosition': position
            });
            break;
        }

        default: {
            console.error('Invalid event:', event);
            break;
        }
    }
}