import { HomepageHeroSlider } from '../homepage/hero-slider';
import { OffersSlider } from '../offers-slider';
import { handleFormSubmit, handleClickOutsideForm } from '@/utils/forms';
import { useGtm } from '@/composables/useGtm';

let gtmIdOperation: string | null;
let gtmProgramName: string | null;

const getFormAttributes = (element: HTMLElement) => {
    const form: HTMLElement | null = element.querySelector('form');

    if(!form) return;

    gtmIdOperation = form?.getAttribute('data-program-id-operation');
    gtmProgramName = form?.getAttribute('data-program-name');
}

document.addEventListener("DOMContentLoaded", function () {
    const submitButton: HTMLButtonElement | null = document.querySelector(".submitRappelForm");

    const programForm: HTMLElement | null = document.querySelector(
        ".rappelContactFormDialog",
    );

    
    const closeRappelContactFormDialog: HTMLElement | null = document.querySelector(
        "#closeRappelContactFormDialog",
    );
    
    if (!programForm) return;
    
    let sliderType: String | null
    
    getFormAttributes(programForm);

    const closeForm = () => {
        programForm?.classList.add("hidden");
        // document.querySelector('body')?.classList.remove("overflow-hidden");
        if (sliderType) {
            programForm?.querySelector(`[data-slider-type="${sliderType}"]`)?.classList.add('hidden');
        } else {
            programForm?.querySelector('.fallbackImage')?.classList.add('hidden');
        }
    }

    document.querySelectorAll(".openRappelForm")?.forEach((element) => {
        element?.addEventListener("click", (e) => {
            e.stopPropagation();

            if(programForm && !programForm.classList.contains('hidden')) {
                handleClickOutsideForm(programForm);
            }

            programForm?.classList.toggle("hidden");
            useGtm('click_callback')

            sliderType = element.getAttribute("data-slider-type");
            const targetSection = programForm?.querySelector(`[data-slider-type="${sliderType}"]`);
            if (targetSection) {
                let slider
                if(sliderType === 'image') {
                    slider = new HomepageHeroSlider('.programContactOffersSlider')
                    if(slider) targetSection.classList.remove('hidden');
                } else {
                    slider = new OffersSlider()
                    targetSection.classList.remove('hidden');
                }
            } else {
                programForm?.querySelector('.fallbackImage')?.classList.remove('hidden');
            }
        });
    });

    closeRappelContactFormDialog?.addEventListener("click", () => {
        closeForm();
    });

    programForm?.addEventListener("submit", async (event) => {
            event.preventDefault();
            const formData = new FormData(event.target as HTMLFormElement);
            await handleFormSubmit(formData, submitButton, 'rappelContactFormDialog', 'rappel', {
                form: {
                    id: "Rappel form",
                    title: "Rappel form"
                },
                program: {
                    id: gtmIdOperation ?? '',
                    title: gtmProgramName ?? '',
                },
            });

            useGtm('submit_callback');
    });
});
