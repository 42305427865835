<script setup lang="ts">
import { ref, onMounted } from 'vue'

// Components
import ResultCard from '@/vue/program/ResultCard.vue';

// Props
const props = defineProps({
    programs: {
        default: [],
        type: Array as () => Program[],
    },
    displayedComponent: {
        default: 'all',
    },
})

const programs = ref([] as Program[]);

onMounted(() => {
    programs.value = props.programs;
})
</script>

<template>
    <p class="w-full text-start text-primary text-2xl">
        Ces adresses peuvent aussi vous intéresser
    </p>
    <div 
        class="lg:grid gap-4"
        :class="[
            displayedComponent === 'all' && 'lg:grid-cols-2',
            displayedComponent === 'list' && 'lg:grid-cols-4',
            displayedComponent === 'map' && 'hidden'
        ]"
    >

    <template v-for="program in programs"
        :key="program.id" 
    >
        <ResultCard :program="program" />
    </template>
</div>

</template>