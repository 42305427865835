import { ref, onUnmounted } from 'vue'
import { debounce } from 'lodash'

export function useLoadingState(delay = 500) {
    const isLoading = ref(false)
    const pendingRequests = ref(0)
    
    // Debounced function to clear loading state
    const debouncedClearLoading = debounce(() => {
        if (pendingRequests.value === 0) {
            isLoading.value = false
        }
    }, delay)
    
    // Start a new request
    const startRequest = () => {
        pendingRequests.value++
        isLoading.value = true
    }
    
    // End a request
    const endRequest = () => {
        pendingRequests.value = Math.max(0, pendingRequests.value - 1)
        debouncedClearLoading()
    }
    
    // Clean up on component unmount
    onUnmounted(() => {
        debouncedClearLoading.cancel()
    })
    
    return {
        isLoading,
        startRequest,
        endRequest,
        pendingRequests
    }
}