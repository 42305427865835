<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'
import axios from "axios"
import { useDebounceFn, onClickOutside } from '@vueuse/core'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
    label: {
        type: String,
    },
    name: {
        type: String,
    },
    value: {
        type: Array,
        default: []
    },
    action: {
        type: String,
        required: true
    },
    multiple: {
        type: Boolean,
        default: true
    }
})

const isDesktop = window.innerWidth > 768
const maxItems = isDesktop ? 2 : 5

const isLoading = ref<Boolean>(false)
const clickOutsideTarget = ref(null)
const inputFocused = ref<Boolean>(false)
const searchResultsDisplayed = ref<Boolean>(false)
const itemsList = ref<Array<Object>>([])
const selectedItems = ref<Array<Object>>(props.value ?? [])
const formValue = ref('')
const searchInput = ref(null)

const searchDebounce = useDebounceFn((value: String) => {
    if(value.length < 2) {
        searchResultsDisplayed.value = false
        return
    }
    search(value)
}, 300)

const search = async (value: String) => {
    try {
        isLoading.value = true
        const response = await axios.get(`${window.urls?.ajax}?action=${props.action}&search=${value}`)

        if(response.status !== 200) throw new Error('Error while fetching data')

        if(response.data) {
            itemsList.value = response.data.programs
            searchResultsDisplayed.value = true
        }

    } catch (error) {
        console.error(error)
    } finally {
        isLoading.value = false
    }
}

const handleSelectItem = (item: Object) => {
    if (!props.multiple) {
        if(selectedItems.value.length === 1 && selectedItems.value[0] === item) {
            selectedItems.value = []
            formValue.value = ''
            emit('update:modelValue', '')
        } else {
            selectedItems.value = [item]
            formValue.value = item.value
            emit('update:modelValue', item.value)
        }
        return
    }

    const index = selectedItems.value.findIndex(el => el === item);
    
    if (index >= 0) {
        selectedItems.value.splice(index, 1);
    } else {
        selectedItems.value.push(item);
    }
    emit('update:modelValue', selectedItems.value);
}

const resetField = () => {
    selectedItems.value = []
    formValue.value = ''
    if (searchInput.value) {
        searchInput.value.value = ''
    }
    emit('update:modelValue', '')
}

onClickOutside(clickOutsideTarget, () => {
    searchResultsDisplayed.value = false
})

watch(() => props.value, (newValue) => {
    selectedItems.value = newValue
}, { deep: true })

onMounted(() => {
    // Listen for form reset event
    if (searchInput.value) {
        const form = searchInput.value.closest('form')
        if (form) {
            form.addEventListener('reset', resetField)
        }
    }
})
</script>

<template>
    <div class="w-full relative mt-2 z-50">
        <input type="hidden" :name="name" :value="formValue">
        
        <label
            :for="`${name}`"
            class="absolute transition-transform duration-150 text-white text-base opacity-50 uppercase"
            :class="inputFocused || selectedItems.length > 0 ? '-translate-y-5 text-xs' : ''"
        >
            {{ label }}
        </label>

        <ul v-if="!!selectedItems.length" class="text-primary flex gap-2 flex-wrap pb-2 absolute">
            <li
                @click.stop="handleSelectItem(item)"
                v-for="item in selectedItems.slice(0, maxItems)"
                class="p-2 bg-primary text-white rounded-md text-xs w-fit flex items-center gap-1"
            >
                {{ item.value }}
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                    <path d="M8.53516 1.9646L1.46409 9.03567" stroke="white"/>
                    <path d="M1.46484 1.9646L8.53591 9.03567" stroke="white"/>
                </svg>
            </li>
            <li
                @click="() => displayValues = true"
                v-if="selectedItems.length > maxItems"
                class="min-w-fit p-2 bg-primary text-white rounded-md text-xs w-fit"
            >... (+{{ selectedItems.length - maxItems  }}) </li>
        </ul>

        <input
            ref="searchInput"
            :name="`${name}_search`"
            :value="value.value"
            @focus="inputFocused = true"
            @blur="inputFocused = false"
            @input="searchDebounce($event?.target?.value)"
            type="text"
            class="w-full border-b border-white px-2 py-1 bg-transparent text-white"
        >

        <template v-if="isLoading">
            <div class="absolute w-full p-2 text-center z-20">
                <p class="animate-pulse text-white">Chargement...</p>
            </div>
        </template>

        <template v-if="searchResultsDisplayed">
            <ul
                ref="clickOutsideTarget"
                v-if="!!itemsList.length" class="space-y-2 pt-2 absolute z-50 w-full p-2 shadow-md bg-white"
            >
                <li
                    v-for="item in itemsList"
                    class="flex gap-2 items-center"
                    :class="selectedItems.find(el => el === item) && 'text-primary'"
                    @click="handleSelectItem(item)"
                    :key="item.key"
                >
                    <div class="w-3 h-3 border border-primary rounded-sm grid place-content-center">
                        <div v-if="selectedItems.find(el => el === item)" class="bg-primary w-2 h-2 rounded-sm"></div>
                    </div>
                    <p class="grow text-primary">{{ item.value }}</p>
                </li>
            </ul>
        </template>
    </div>
</template>